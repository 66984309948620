import React, { useEffect } from "react";

const ConfigForCover = ({ coverConfig, setCoverConfig, ordersData }) => {
  useEffect(() => {
    if (typeof ordersData !== "undefined") {
      setCoverConfig(
        JSON.stringify(ordersData["configs"]["cover_configs"], null, 2)
      );
    } else {
      fetch("/cover_config.json")
        .then((response) => response.text())
        .then((data) => setCoverConfig(data))
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, []);

  const handleChange = (e) => {
    setCoverConfig(e.target.value);
  };

  return (
    <div className="mt-10 shadow-lg bg-gray-100 rounded-lg pl-3 pb-3 pt-2">
      <div className="text-center font-bold pb-2">Config for Cover</div>
      <textarea
        type="text"
        id="title"
        value={coverConfig}
        onChange={handleChange}
        placeholder="Enter the config for the cover generation.."
        style={{
          width: "280px",
          height: "175px",
          border: "1px solid black",
          borderRadius: "10px",
          padding: "5px",
        }}
      />
      <a
        href="https://platform.openai.com/docs/guides/images/usage"
        target="_blank"
        rel="noopener noreferrer"
        class="text-blue-500 hover:underline"
      >
        ** Click here for documentation
      </a>
    </div>
  );
};

export default ConfigForCover;
