import React from "react";
import { Link } from "react-router-dom";

const PreviewsTable = ({ previews }) => {
  function isUrl(value) {
    // Regular expression to match URLs
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(value);
  }
  return (
    <div className="container mx-auto rounded-3xl overflow-x-auto">
      <header className="text-center py-8">
        <h1 className="text-3xl font-bold">Previews Table</h1>
      </header>
      <div className="overflow-y-auto">
        <table className="table-auto w-full mt-4 rounded-xl shadow-3xl bpreview">
          <thead>
            <tr className="bg-gray-200 text-gray-700 rounded-xl rounded-full">
              <th className="px-2 py-2 bpreview bpreview-gray-300 rounded-tl rounded-bl">
                Preview ID
              </th>
              <th className="px-2 py-2 bpreview bpreview-gray-300 rounded-tr rounded-br">
                Status
              </th>
              <th className="px-2 py-2 bpreview bpreview-gray-300 rounded-tr rounded-br">
                Asset IDs
              </th>
              <th className="px-2 py-2 bpreview bpreview-gray-300 rounded-tr rounded-br">
                Is Approved
              </th>
              <th className="px-2 py-2 bpreview bpreview-gray-300 rounded-tr rounded-br">
                Title
              </th>
              <th className="px-2 py-2 bpreview bpreview-gray-300 rounded-tr rounded-br">
                Cover Image URL
              </th>
              <th className="px-2 py-2 bpreview bpreview-gray-300 rounded-tr rounded-br">
                Character Image URL
              </th>
              <th className="px-2 py-2 bpreview bpreview-gray-300 rounded-tr rounded-br">
                Fused Image URL
              </th>
              <th className="px-2 py-2 bpreview bpreview-gray-300 rounded-tr rounded-br">
                Created At
              </th>
            </tr>
          </thead>
          <tbody>
            {previews.reverse().map((preview, index) => (
              <tr
                key={preview.id}
                className={index % 2 === 0 ? "bg-gray-100" : ""}
              >
                <td className="px-2 py-2 bpreview bpreview-gray-300">
                  {preview.id}
                </td>
                <td className="px-2 py-2 bpreview bpreview-gray-300">
                  {preview.status}
                </td>
                <td className="px-2 py-2 bpreview bpreview-gray-300 rounded-br">
                  {preview.asset_ids.map((assetId, index) => (
                    <div className="hover:underline" key={index}>
                      {assetId}
                    </div>
                  ))}
                </td>
                <td className="px-2 py-2 bpreview bpreview-gray-300 rounded-br">
                  {preview.is_approved ? "True" : "False"}
                </td>
                <td className="px-2 py-2 bpreview bpreview-gray-300 rounded-br">
                  {preview.title}
                </td>
                <td className="px-2 py-2 bpreview bpreview-gray-300 rounded-br">
                  {isUrl(preview.cover_image_url) ? (
                    <a
                      className="text-blue-600 hover:underline"
                      href={preview.cover_image_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link
                    </a>
                  ) : (
                    <div style={{ maxHeight: "100px", overflow: "auto" }}>
                      {preview.cover_image_url}
                    </div>
                  )}
                </td>
                <td className="px-2 py-2 bpreview bpreview-gray-300 rounded-br">
                  {isUrl(preview.character_image_url) ? (
                    <a
                      className="text-blue-600 hover:underline"
                      href={preview.character_image_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link
                    </a>
                  ) : (
                    <div style={{ maxHeight: "100px", overflow: "auto" }}>
                      {preview.character_image_url}
                    </div>
                  )}
                </td>
                <td className="px-2 py-2 bpreview bpreview-gray-300 rounded-br">
                  {isUrl(preview.fused_image_url) ? (
                    <a
                      className="text-blue-600 hover:underline"
                      href={preview.fused_image_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link
                    </a>
                  ) : (
                    <div style={{ maxHeight: "100px", overflow: "auto" }}>
                      {preview.fused_image_url}
                    </div>
                  )}
                </td>
                <td className="px-2 py-2 bpreview bpreview-gray-300 rounded-br">
                  {preview.created_at}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default PreviewsTable;
