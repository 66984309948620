import React, { useState } from "react";
import DialogBox from "../../common/DialogBox";

const TitleGrid = ({ assets, onUpdateSelectedTextId }) => {
  const [selectedTextId, setSelectedTextId] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isBlurbOpen, setIsBlurbOpen] = useState(false);
  const [blurbValue, setBlurbValue] = useState("false");

  const validTitleValues = assets
    .filter((asset) => asset.type === "TITLE" && asset.category === "VALID")
    .map((asset) => ({
      id: asset.id,
      value: asset.value,
      blurb: asset.blurb,
    }));

  const selectableTitleValues = assets
    .filter(
      (asset) => asset.type === "TITLE" && asset.category === "SELECTABLE"
    )
    .map((asset) => ({
      id: asset.id,
      value: asset.value,
      blurb: asset.blurb,
    }));

  const badTitleValues = assets
    .filter((asset) => asset.type === "TITLE" && asset.category === "BAD")
    .map((asset) => ({
      id: asset.id,
      value: asset.value,
      blurb: asset.blurb,
    }));

  const titlePrompt = assets.filter((asset) => asset.type === "TITLE")[0][
    "prompt"
  ];
  console.log("titlePrompt", titlePrompt);

  const handleRadioChange = (id) => {
    setSelectedTextId(id);
    onUpdateSelectedTextId(id);
  };
  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const formatBlurb = (blurb) => {
    console.log("blurb: \n", blurb);

    const updatedBlurb = blurb.replace(
      /&&(\s*\n)?(\s*\n)?\s*/g,
      (match, p1, p2) => {
        if (p1 && p2) {
          return "&&"; // Preserve existing \n\n
        } else if (p1) {
          return "\n\n"; // Add just one \n
        } else {
          return "\n\n"; // Add two \n\n
        }
      }
    );

    console.log("updatedBlurb: \n", updatedBlurb);
    return updatedBlurb;
  }

  return (
    <>
      <button
        className="text-blue-500 hover:text-blue-700"
        onClick={handleIsOpen}
      >
        **Click for prompt
      </button>
      <div className="grid-cols-1 gap-4 mt-8">
        {selectableTitleValues.map((item) => (
          <div key={item.id} className="flex justify-start">
            <input
              type="radio"
              id={`title-radio-${item.id}`}
              name="title-radio"
              checked={selectedTextId === item.id}
              onChange={() => handleRadioChange(item.id)}
              className="mr-2 ml-10"
            />
            <div className="justify-start ml-2">
              <div
                className="cursor-pointer"
                onClick={() => {
                  setIsBlurbOpen(true);
                  setBlurbValue(formatBlurb(item.blurb));
                }}
              >
                {item.value}
              </div>
            </div>
          </div>
        ))}
        {validTitleValues.map((item) => (
          <div key={item.id} className="flex justify-start">
            <div className="justify-start ml-14 bg-yellow-100">
              <div
                className="cursor-pointer"
                onClick={() => {
                  setIsBlurbOpen(true);
                  setBlurbValue(formatBlurb(item.blurb));
                }}
              >
                {item.value}
              </div>
            </div>
          </div>
        ))}
        {badTitleValues.map((item) => (
          <div key={item.id} className="flex justify-start">
            <div className="justify-start ml-14 bg-red-300">
              <div
                className="cursor-pointer"
                onClick={() => {
                  setIsBlurbOpen(true);
                  setBlurbValue(formatBlurb(item.blurb));
                }}
              >
                {item.value}
              </div>
            </div>
          </div>
        ))}

        <div>
          <DialogBox isOpen={isOpen} setIsOpen={setIsOpen} prompt={titlePrompt}>
            <p className="font-bold text-xl">
              Please find prompt used for titles as follows:
            </p>
          </DialogBox>

          <DialogBox
            isOpen={isBlurbOpen}
            setIsOpen={setIsBlurbOpen}
            prompt={blurbValue}
          >
            <p className="font-bold text-xl">
              Please find the blurb as follows:
            </p>
          </DialogBox>
        </div>
      </div>
    </>
  );
};

export default TitleGrid;
