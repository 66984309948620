import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import HeaderComponent from "./components/common/Header";
import Orders from "./pages/Orders";
import Assets from "./pages/Assets";
import Previews from "./pages/Previews";
import PreviewGenerator from "./pages/PreviewGenerator";
import LoginButton from "./pages/Login";
import CallBack from "./pages/Callback";
import { useAuth0 } from "@auth0/auth0-react";
function App() {
  const { user } = useAuth0();

  return (
    <>
      {user && <HeaderComponent />}
      <Router>
        <Routes>
          {user ? (
            <>
              <Route path="/home" element={<PreviewGenerator />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/assets/" element={<Assets />} />
              <Route path="/previews" element={<Previews />} />
              <Route path="/pg/:order_id" element={<PreviewGenerator />} />
            </>
          ) : (
            <>
              <Route path="/login" element={<LoginButton />} />
              <Route path="/callback" element={<CallBack />} />
              <Route path="/" element={<Navigate to="/login" />} />
            </>
          )}
        </Routes>
      </Router>
    </>
  );
}

export default App;
