import React from "react";
import { Link } from "react-router-dom";

const OrdersTable = ({ orders }) => {
  return (
    <div className="container mx-auto rounded-4xl overflow-x-auto">
      <header className="text-center py-8">
        <h1 className="text-3xl font-bold">Orders Table</h1>
      </header>
      <div className="overflow-y-auto">
        <table className="table-auto w-full mt-4 rounded-xl shadow-3xl border">
          <thead>
            <tr className="bg-gray-200 text-gray-700 rounded-xl rounded-full">
              <th className="px-2 py-2 border border-gray-300 rounded-tl rounded-bl">
                Order ID
              </th>
              <th className="px-2 py-2 border border-gray-300">Email</th>
              <th className="px-2 py-2 border border-gray-300">Name</th>
              <th className="px-2 py-2 border border-gray-300 rounded-tr rounded-br">
                City
              </th>
              <th className="px-2 py-2 border border-gray-300 rounded-tr rounded-br">
                Birthday
              </th>
              <th className="px-2 py-2 border border-gray-300 rounded-tr rounded-br">
                Favourite Food
              </th>
              <th className="px-2 py-2 border border-gray-300 rounded-tr rounded-br">
                Interests
              </th>
              <th className="px-2 py-2 border border-gray-300 rounded-tr rounded-br">
                Events to come
              </th>
              <th className="px-2 py-2 border border-gray-300 rounded-tr rounded-br">
                Skin tone
              </th>
              <th className="px-2 py-2 border border-gray-300 rounded-tr rounded-br">
                Hair color
              </th>
              <th className="px-2 py-2 border border-gray-300 rounded-tr rounded-br">
                Hair length
              </th>
              <th className="px-2 py-2 border border-gray-300 rounded-tr rounded-br">
                Kids photo
              </th>
              <th className="px-2 py-2 border border-gray-300 rounded-tr rounded-br">
                Story message
              </th>
              <th className="px-2 py-2 border border-gray-300 rounded-tr rounded-br">
                Favourite Place
              </th>
              <th className="px-2 py-2 border border-gray-300 rounded-tr rounded-br">
                Personal Dedication
              </th>
              <th className="px-2 py-2 border border-gray-300 rounded-tr rounded-br">
                Created At
              </th>
            </tr>
          </thead>
          <tbody>
            {orders.reverse().map((order, index) => (
              <tr
                key={order.id}
                className={index % 2 === 0 ? "bg-gray-100" : ""}
              >
                <td className="text-blue-600 hover:underline px-2 py-2 border border-gray-300 rounded-bl">
                  <Link to={`/pg/${order.id}`}>{order.id}</Link>
                </td>
                <td className="px-2 py-2 border border-gray-300">
                  {order.email}
                </td>
                <td className="px-2 py-2 border border-gray-300">
                  {order.name}
                </td>
                <td className="px-2 py-2 border border-gray-300 rounded-br">
                  {order.city}
                </td>
                <td className="px-2 py-2 border border-gray-300 rounded-br">
                  {order.birthday}
                </td>
                <td className="px-2 py-2 border border-gray-300 rounded-br">
                  {order.favourite_food}
                </td>
                <td className="px-2 py-2 border border-gray-300 rounded-br">
                  {order.interests}
                </td>
                <td className="px-2 py-2 border border-gray-300 rounded-br">
                  {order.event_to_come}
                </td>
                <td className="px-2 py-2 border border-gray-300 rounded-br">
                  {order.skin_tone}
                </td>
                <td className="px-2 py-2 border border-gray-300 rounded-br">
                  {order.hair_color}
                </td>
                <td className="px-2 py-2 border border-gray-300 rounded-br">
                  {order.hair_length}
                </td>
                <td className="px-2 py-2 border border-gray-300 rounded-br">
                  {order.kids_photo}
                </td>
                <td className="px-2 py-2 border border-gray-300 rounded-br">
                  <div style={{ maxHeight: "100px", overflow: "auto" }}>
                    {order.story_message}
                  </div>
                </td>
                <td className="px-2 py-2 border border-gray-300 rounded-br">
                  {order.favourite_place}
                </td>
                <td className="px-2 py-2 border border-gray-300 rounded-br">
                  {order.personal_dedication}
                </td>
                <td className="px-2 py-2 border border-gray-300 rounded-br">
                  {order.created_at}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default OrdersTable;
