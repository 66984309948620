import React from "react";

const DialogBox = ({ isOpen, setIsOpen, children, prompt }) => {
  if (!isOpen) return null;
  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <div
        className="bg-gray-200 shadow-gray-600 shadow-2xl p-10 rounded-lg relative"
        style={{ width: "900px" }}
      >
        <button
          className=" text-3xl top-0 right-0"
          style={{ color: "red" }}
          onClick={handleIsOpen}
        >
          X
        </button>
        {children}
        <div className="mt-4">
          <textarea
            className="bg-gray-100 border border-gray-300 rounded-lg p-2 w-full h-full"
            value={prompt}
            style={{ width: "100%", height: "300px" }}
            readOnly
          />
        </div>
      </div>
    </div>
  );
};

export default DialogBox;
