import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const buttonStyle = {
    backgroundColor: "#4CAF50",
    border: "none",
    color: "white",
    padding: "15px 22px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "16px",
    margin: "4px 2px",
    cursor: "pointer",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease-in-out",
    width: "250px",
    height: "60px",
  };

  const handleClick = () => {
    loginWithRedirect({
      appState: { returnTo: "/home" },
    });
  };

  return (
    <div>
      <div className="grid grid-cols-6">
        <div
          className="col-span-4"
          style={{
            background: "rgb(11, 11, 69)",
            backgroundSize: "cover",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
        <div
          style={{
            background: "white",
            backgroundSize: "cover",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="font-bold text-2xl">
            Login
            <br />
            <br />
            <br />
            <button style={buttonStyle} onClick={handleClick}>
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginButton;
