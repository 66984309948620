import React from "react";

const CharactersGrid = ({ assets }) => {
  const characterValues = assets
    .filter((asset) => asset.type === "CHARACTER_IMAGE")
    .map((asset) => asset.value);
  const character = [characterValues[0]];

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="grid grid-cols-1 gap-4 ml-10 mr-20 mt-3">
          {/* This is a dirty hack, patching this until i dont generate multiple char images on the backend */}

          {character.map((url, index) => (
            <a key={index} href={url} target="_blank" rel="noopener noreferrer">
              <img
                src={url}
                alt=""
                style={{ padding: "2px", width: "90px", height: "150px" }}
              />
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default CharactersGrid;
