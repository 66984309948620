import React from "react";
import PreviewsTable from "../components/previews/tables/PreviewsTable";
import { useQuery } from "react-query";

const Previews = () => {
  const API_URL = process.env.REACT_APP_API_URL + "/previews";
  const { data, isLoading, error } = useQuery("previews", async () => {
    const response = await fetch(API_URL, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${process.env.REACT_APP_API_BEARER_TOKEN}`,
      },
    });
    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }
    const result = await response.json();
    console.log(result, "result");
    return result;
  });

  if (isLoading) {
    return <p>Loading previews...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className="container mx-auto">
      <header className="text-center py-8">
        <PreviewsTable previews={data} />
      </header>
    </div>
  );
};

export default Previews;
