import React from "react";
import LogoutButton from "../../pages/Logout";
import { useAuth0 } from "@auth0/auth0-react";

const Header = () => {
  const { user } = useAuth0();
  return (
    <header className="bg-black text-white shadow-md">
      <div className="container mx-auto py-4">
        <nav className="flex justify-between items-center">
          <div>
            <a href="/home" className="text-2xl font-bold">
              Das bin ja ich!
            </a>
          </div>
          <div>
            <ul className="flex space-x-4">
              {user && (
                <>
                  <li>
                    <a href="/orders" className="hover:text-gray-300">
                      Orders
                    </a>
                  </li>
                  <li>
                    <a href="/assets" className="hover:text-gray-300">
                      Assets
                    </a>
                  </li>
                  <li>
                    <a href="/previews" className="hover:text-gray-300">
                      Previews
                    </a>
                  </li>
                  <li>
                    <LogoutButton />
                  </li>
                </>
              )}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
