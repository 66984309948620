import React from "react";

const AssetsTable = ({ assets }) => {
  function isUrl(value) {
    // Regular expression to match URLs
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(value);
  }
  return (
    <div className="container mx-auto rounded-3xl overflow-x-auto">
      <header className="text-center py-8">
        <h1 className="text-3xl font-bold">Assets Table</h1>
      </header>
      <div className="overflow-y-auto">
        <table className="table-auto w-full mt-4 rounded-xl shadow-3xl basset">
          <thead>
            <tr className="bg-gray-200 text-gray-700 rounded-xl rounded-full">
              <th className="px-2 py-2 basset basset-gray-300 rounded-tl rounded-bl">
                Asset ID
              </th>
              <th className="px-2 py-2 basset basset-gray-300 rounded-tl rounded-bl">
                Order ID
              </th>
              <th className="px-2 py-2 basset basset-gray-300 rounded-tl rounded-bl">
                Prompt
              </th>
              <th className="px-2 py-2 basset basset-gray-300 rounded-tl rounded-bl">
                Value
              </th>
              <th className="px-2 py-2 basset basset-gray-300 rounded-tl rounded-bl">
                Type
              </th>
              <th className="px-2 py-2 basset basset-gray-300 rounded-tl rounded-bl">
                Status
              </th>
              <th className="px-2 py-2 basset basset-gray-300 rounded-tl rounded-bl">
                Created At
              </th>
            </tr>
          </thead>
          <tbody>
            {assets.reverse().map((asset, index) => (
              <tr
                key={asset.id}
                className={index % 2 === 0 ? "bg-gray-100" : ""}
              >
                <td className="px-2 py-2 basset basset-gray-300 rounded-bl">
                  {asset.id}
                </td>
                <td className="px-2 py-2 basset basset-gray-300 rounded-bl">
                  {asset.order_id}
                </td>
                <td
                  className="px-2 py-2 basset basset-gray-300 rounded-bl"
                  style={{ height: "100px", overflow: "hidden" }}
                >
                  <div style={{ maxHeight: "100px", overflow: "auto" }}>
                    {asset.prompt}
                  </div>
                </td>
                <td className="px-2 py-2 basset basset-gray-300 rounded-bl">
                  {isUrl(asset.value) ? (
                    <a
                      className="text-blue-600 hover:underline"
                      href={asset.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link
                    </a>
                  ) : (
                    <div style={{ maxHeight: "100px", overflow: "auto" }}>
                      {asset.value}
                    </div>
                  )}
                </td>
                <td className="px-2 py-2 basset basset-gray-300 rounded-bl">
                  {asset.type}
                </td>
                <td className="px-2 py-2 basset basset-gray-300 rounded-bl">
                  {asset.status}
                </td>
                <td className="px-2 py-2 basset basset-gray-300 rounded-bl">
                  {asset.created_at}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default AssetsTable;
