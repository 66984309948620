import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import DialogBox from "../common/DialogBox";
const CreateOrder = ({
  ordersFormData,
  titlePrompt,
  coverPrompt,
  titleConfig,
  coverConfig,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate();
  let orders_json;
  let cover_config_json;
  let title_config_json;

  if (ordersFormData !== null || coverConfig !== null || titleConfig !== null) {
    try {
      orders_json = JSON.parse(ordersFormData);
      cover_config_json = JSON.parse(coverConfig);
      title_config_json = JSON.parse(titleConfig);
      // Handle parsed JSON data
    } catch (error) {
      console.error("Error parsing JSON:", error);
      // Handle parsing error
    }
  }

  function gettingFirstAssetIds(assets) {
    let assetIds = [];

    for (let asset of assets) {
      if (asset["type"] === "TITLE" && asset["category"] === "SELECTABLE") {
        assetIds.push(asset.id);
        break;
      }
    }

    for (let asset of assets) {
      if (asset["type"] === "BACKGROUND_IMAGE") {
        assetIds.push(asset.id);
        break;
      }
    }

    for (let asset of assets) {
      if (asset["type"] === "CHARACTER_IMAGE") {
        assetIds.push(asset.id);
        break;
      }
    }

    return assetIds;
  }

  const formData = {
    ...orders_json,
    prompts: {
      cover_prompt: coverPrompt,
      title_prompt: titlePrompt,
    },
    configs: {
      cover_configs: cover_config_json,
      title_configs: title_config_json,
    },
  };

  // Creating Orders
  const ORDERS_GENERATION_API_URL = `${process.env.REACT_APP_API_URL}/orders/`;
  const PREVIEW_GENERATION_API_URL = `${process.env.REACT_APP_API_URL}/previews/`;

  const postData = async () => {
    const body = JSON.stringify(formData);

    const response = await fetch(ORDERS_GENERATION_API_URL, {
      method: "POST",
      body: body,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${process.env.REACT_APP_API_BEARER_TOKEN}`,
      },
    });
    const data = await response.json();
    return data;
  };

  const postDataPreviews = async (body) => {
    const response = await fetch(PREVIEW_GENERATION_API_URL, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${process.env.REACT_APP_API_BEARER_TOKEN}`,
      },
    });
    const data = await response.json();
    return data;
  };

  async function fetchOrderStatus(id) {
    const url = `${process.env.REACT_APP_API_URL}/orders/order_status/${id}`;
    try {
      const response = await fetch(url, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${process.env.REACT_APP_API_BEARER_TOKEN}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching order status:", error);
      throw error;
    }
  }

  const { mutate, isLoading } = useMutation(postData, {
    onSuccess: async (successData) => {
      console.log("success Data", successData);
      try {
        const MAX_RETRIES = 12;
        let retries = 0;
        while (retries < MAX_RETRIES) {
          let statusData = await fetchOrderStatus(successData.id);
          console.log("statusData", statusData);
          if (statusData === true) {
            console.log("statusData", statusData);
            break;
          }
          await new Promise((resolve) => setTimeout(resolve, 5000));
          retries++;
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/assets/order_id/${successData.id}`,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${process.env.REACT_APP_API_BEARER_TOKEN}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch assets data");
        }
        const assets = await response.json();
        const previewBody = {
          order_id: successData.id,
          asset_ids: gettingFirstAssetIds(assets),
        };
        await mutatePreviews(previewBody);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    onError: () => {
      setIsOpen(true);
    },
  });

  const { mutate: mutatePreviews, isLoading: isLoadingPreviews } = useMutation(
    postDataPreviews,
    {
      onSuccess: (successData) => {
        console.log("success Data", successData);
        navigate(`/pg/${successData.order_id}`);
        window.location.reload();
      },
    }
  );
  return (
    <>
      <button
        type="submit"
        onClick={mutate}
        className={`mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
          isLoading || isLoadingPreviews ? "opacity-50 cursor-not-allowed" : ""
        }`}
        disabled={isLoading || isLoadingPreviews}
      >
        {isLoading || isLoadingPreviews ? "Loading..." : "Submit"}
      </button>
      <DialogBox
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        prompt={`
        Something went wrong!
        
        Please try again with valid inputs

        In case you are unable to fix the inputs, please refresh the page for default inputs

        (or)

        Refer to the documentation of the title and cover configs and adjust the configs as applicable.
        
        `}
      >
        <div> Oops! Errored Out</div>
      </DialogBox>
    </>
  );
};

export default CreateOrder;
