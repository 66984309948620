import React, { useEffect } from "react";

const TitlePromptBox = ({ titlePrompt, setTitlePrompt, ordersData }) => {
  useEffect(() => {
    if (typeof ordersData !== "undefined") {
      setTitlePrompt(ordersData["prompts"]["title_prompt"]);
    } else {
      fetch("/book_title_template.txt")
        .then((response) => response.text())
        .then((data) => setTitlePrompt(data))
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, []);

  const handleChange = (e) => {
    setTitlePrompt(e.target.value);
  };

  return (
    <div className="mt-10 shadow-lg bg-gray-100 rounded-lg pl-6 pb-3 pt-2">
      <div className="text-center font-bold pb-2">Title Prompt Box</div>
      <textarea
        type="text"
        id="title-prompt-box"
        value={titlePrompt}
        onChange={handleChange}
        placeholder="Enter title prompt..."
        style={{
          width: "600px",
          height: "200px",
          border: "1px solid black",
          borderRadius: "10px",
          padding: "5px",
        }}
      />
    </div>
  );
};

export default TitlePromptBox;
