import React, { useState } from "react";
import DialogBox from "../../common/DialogBox";

const CoversGrid = ({ assets, onUpdateSelectedCoverId }) => {
  const [selectedCoverId, setSelectedCoverId] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [assetId, setAssetId] = useState(null);

  const handleIsOpen = (id) => {
    setIsOpen(!isOpen);
    setAssetId(id);
  };
  const coverValues = assets
    .filter((asset) => asset.type === "BACKGROUND_IMAGE")
    .map((asset) => ({
      id: asset.id,
      value: asset.value,
    }));
  console.log(assets, "assets");
  const coverPrompt = assets.filter(
    (asset) =>
      asset.type === "BACKGROUND_IMAGE" &&
      (assetId === null || asset.id === assetId)
  )[0]["prompt"];
  console.log("coverPrompt", coverPrompt);

  const revisedCoverPrompt = assets.filter(
    (asset) =>
      asset.type === "BACKGROUND_IMAGE" &&
      (assetId === null || asset.id === assetId)
  )[0]["revised_cover_prompt"];

  const combinedPrompt =
    "Inputted Cover Prompt:\n\n" +
    coverPrompt +
    "\n\n\n" +
    "Revised Cover Prompt:\n\n" +
    revisedCoverPrompt;

  const handleRadioChange = (id) => {
    setSelectedCoverId(id);
    onUpdateSelectedCoverId(id);
  };

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "center" }}
        className="ml-10 mr-10"
      >
        <div className="grid grid-cols-3 gap-4 ">
          {coverValues.map((item) => (
            <div>
              <button
                className="text-blue-500 hover:text-blue-700"
                onClick={() => handleIsOpen(item.id)}
              >
                **Click for prompt
              </button>
              <div key={item.id} className="flex items-center">
                <input
                  type="radio"
                  id={`cover-radio-${item.id}`}
                  name="cover-radio"
                  checked={selectedCoverId === item.id}
                  onChange={() => handleRadioChange(item.id)}
                  className="mr-2"
                />
                <a href={item.value} target="_blank" rel="noopener noreferrer">
                  <img
                    key={item.id}
                    src={item.value}
                    alt=""
                    style={{ padding: "2px", width: "400px", height: "283px" }}
                  />
                </a>
              </div>
            </div>
          ))}
        </div>
        <div>
          <DialogBox
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            prompt={combinedPrompt}
          >
            <p className="font-bold text-xl">
              Please find prompt used for the given cover as follows:
            </p>
          </DialogBox>
        </div>
      </div>
    </>
  );
};

export default CoversGrid;
