import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import CharactersGrid from "../components/orders/outputGrids/CharactersGrid";
import ConsolidatedInputForm from "../components/orders/inputBoxes/ConsolidatedInputForm";
import CoversGrid from "../components/orders/outputGrids/CoversGrid";
import PreviewsGrid from "../components/orders/outputGrids/PreviewsGrid";
import TitleGrid from "../components/orders/outputGrids/TitleGrid";
const PreviewGenerator = () => {
  // For the selectors
  const [selectedCoverId, setSelectedCoverId] = useState();
  const [selectedCharId, setSelectedCharId] = useState();
  const [selectedTextId, setSelectedTextId] = useState();

  // For the inputs
  const [ordersFormData, setOrdersFormData] = useState("");
  const [titlePrompt, setTitlePrompt] = useState("");
  const [coverPrompt, setCoverPrompt] = useState("");
  const [titleConfig, setTitleConfig] = useState("");
  const [coverConfig, setCoverConfig] = useState("");

  const { order_id } = useParams();

  //Getting orders
  const ORDERS_API_URL = `${process.env.REACT_APP_API_URL}/orders/${order_id}`;
  const { data: order, isLoading: isOrderLoading } = useQuery(
    "order",
    async () => {
      const response = await fetch(ORDERS_API_URL, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${process.env.REACT_APP_API_BEARER_TOKEN}`,
        },
      });
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
      const result = await response.json();
      console.log("order", order);

      return result;
    },
    { enabled: !!order_id }
  );

  // Getting Assets
  const ASSETS_API_URL = `${process.env.REACT_APP_API_URL}/assets/order_id/${order_id}`;

  const {
    data: assets,
    isLoading: isLoadingAssets,
    error: errorAssets,
  } = useQuery(
    "assets_by_order_id",
    async () => {
      const response = await fetch(ASSETS_API_URL, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${process.env.REACT_APP_API_BEARER_TOKEN}`,
        },
      });
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
      const result = await response.json();
      setSelectedCharId(
        result
          .filter((asset) => asset.type === "CHARACTER_IMAGE")
          .map((asset) => asset.id)[0]
      );

      return result;
    },
    { enabled: !!order_id }
  );

  // Getting Previews
  const PREVIEWS_API_URL = `${process.env.REACT_APP_API_URL}/previews/order_id/${order_id}`;
  const {
    data: previews,
    isLoading: isLoadingPreviews,
    error: errorPreviews,
    refetch: refetchPreviews,
  } = useQuery(
    "previews_by_order_id",
    async () => {
      const response = await fetch(PREVIEWS_API_URL, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${process.env.REACT_APP_API_BEARER_TOKEN}`,
        },
      });
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
      const result = await response.json();
      return result;
    },
    { enabled: !!order_id }
  );

  // Creating Previews
  const PREVIEWS_GENERATION_API_URL = `${process.env.REACT_APP_API_URL}/previews/`;
  const createPreviewsData = async () => {
    const response = await fetch(PREVIEWS_GENERATION_API_URL, {
      method: "POST",
      body: JSON.stringify({
        order_id: order_id,
        asset_ids: [selectedTextId, selectedCoverId, selectedCharId],
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${process.env.REACT_APP_API_BEARER_TOKEN}`,
      },
    });
    return response;
  };

  const { mutate: previewsMutate, isLoading } = useMutation(
    createPreviewsData,
    {
      onSuccess: (successData) => {
        console.log("previews Data", successData);
        window.location.reload();
      },
    }
  );

  if (isLoadingAssets || isLoadingPreviews || isOrderLoading) {
    return <p>Loading...</p>;
  }

  if (errorAssets || errorPreviews) {
    return <p>Error: {errorAssets || errorPreviews}</p>;
  }

  const handleSelectedCoverIdChange = (newValue) => {
    setSelectedCoverId(newValue);
  };
  const handleSelectedTextIdChange = (newValue) => {
    setSelectedTextId(newValue);
  };

  const handlePreviewGeneration = () => {
    previewsMutate();
  };

  return (
    <>
      <ConsolidatedInputForm
        order={order}
        ordersFormData={ordersFormData}
        setOrdersFormData={setOrdersFormData}
        titlePrompt={titlePrompt}
        setTitlePrompt={setTitlePrompt}
        titleConfig={titleConfig}
        setTitleConfig={setTitleConfig}
        coverPrompt={coverPrompt}
        setCoverPrompt={setCoverPrompt}
        coverConfig={coverConfig}
        setCoverConfig={setCoverConfig}
      />
      {order_id && (
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              marginLeft: "190px",
              marginRight: "0px",
            }}
          >
            <div style={{ flex: 1, marginRight: "10px" }}>
              <div className="font-bold p-8 pb-0 text-xl text-center">
                Title's Grid
              </div>
              <TitleGrid
                assets={assets}
                onUpdateSelectedTextId={handleSelectedTextIdChange}
              />
            </div>
            <div style={{ flex: 1, marginRight: "10px" }}>
              <div className="font-bold p-8 pb-0 text-xl">Characters Grid</div>
              <CharactersGrid assets={assets} />
            </div>
          </div>
          <div style={{ flex: 1, marginRight: "10px" }}>
            <div className="font-bold p-4 pb-0 text-xl">Covers Grid</div>
            <CoversGrid
              assets={assets}
              onUpdateSelectedCoverId={handleSelectedCoverIdChange}
            />
          </div>

          <button
            className={
              "bg-blue-500 hover:bg-blue-700 cursor-pointer text-white font-bold py-2 px-4 m-2 rounded" +
              (isLoading ? " opacity-50 cursor-not-allowed" : "")
            }
            onClick={() => handlePreviewGeneration()}
            disabled={isLoading}
          >
            {isLoading ? "Generating Previews..." : "Generate Previews"}
          </button>

          <div className="text-xl font-bold p-5 bg-slate-200">
            Generated Previews
          </div>
          <div className="mt-2">
            <PreviewsGrid
              previews={previews}
              refetchPreviews={refetchPreviews}
            />
            <br />
          </div>
        </div>
      )}
    </>
  );
};

export default PreviewGenerator;
