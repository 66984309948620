import React, { useEffect } from "react";

const JsonInputComponent = ({
  ordersFormData,
  setOrdersFormData,
  ordersData,
}) => {
  const defaultFormData = {
    email: "test@test.com",
    kids_name: "Test PlayGround",
    kids_gender: "boy",
    hair_color: "blond",
    hair_length: "short",
    color_skin_tone: "fair",
    no_of_covers: 2,
    city: "Hamburg",
    kids_date_of_birth: "2019-01-28",
    favourite_food: "Pizza",
    interest: "Fussball",
    upcoming_life_event: "birthday",
    story_location: "garden",
    intent_message: "Play",
    image: "placeholder",
    dedication: "Für dich meine Liebe",
    total_no_of_titles: 5,
    mood: "Magical",
  };

  useEffect(() => {
    if (typeof ordersData !== "undefined") {
      const keysToExclude = ["prompts", "configs"];

      const filteredData = Object.fromEntries(
        Object.entries(ordersData).filter(
          ([key, _]) => !keysToExclude.includes(key)
        )
      );
      setOrdersFormData(JSON.stringify(filteredData, null, 2));
    } else {
      setOrdersFormData(JSON.stringify(defaultFormData, null, 2));
      console.log("updatedOrdersData", ordersFormData);
    }
  }, []);

  const handleChange = (e) => {
    setOrdersFormData(e.target.value);
  };

  return (
    <div className="max-w-6xl mx-auto mt-8 border rounded-lg shadow-lg bg-gray-100 p-4 text-center">
      <h2 className="text-lg font-semibold mb-4">Create Orders Request</h2>
      <textarea
        value={ordersFormData}
        onChange={handleChange}
        className="mt-1 p-2 w-full border rounded-md resize-none"
        rows={22}
        style={{ fontFamily: "monospace", fontSize: "14px" }}
      />
    </div>
  );
};

export default JsonInputComponent;
