import React from "react";

const PreviewsGrid = ({ previews, refetchPreviews }) => {
  const hasApproved = async (preview_id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/previews/approve/preview_id/?preview_id=${preview_id}`,
      {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${process.env.REACT_APP_API_BEARER_TOKEN}`,
        },
      }
    );
    const data = await response.json();
    refetchPreviews();
    return data;
  };

  return (
    <div
      style={{ display: "flex", justifyContent: "center" }}
      className="ml-12 mr-12"
    >
      <div className="grid grid-cols-3 gap-4">
        {previews.map((preview) => (
          <div>
            <a
              href={preview.fused_image_url}
              key={preview.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={preview.fused_image_url}
                alt=""
                style={{ padding: "2px", width: "400px", height: "283px" }}
              />
            </a>
            <div>
              {preview.is_approved ? (
                <div className="bg-green-400 p-2 rounded">Approved</div>
              ) : (
                <button
                  onClick={() => hasApproved(preview.id)}
                  class={`${
                    preview.is_approved
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-blue-500 hover:bg-blue-700 cursor-pointer"
                  } text-white font-bold py-2 px-4 rounded ${
                    preview.is_approved ? "opacity-50" : ""
                  }`}
                  disabled={preview.is_approved}
                >
                  Approve
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PreviewsGrid;
