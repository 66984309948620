import React from "react";
import JsonInputComponent from "./JsonComponent";
import TitlePromptBox from "./TitlePromptBox";
import ConfigForTitle from "./ConfigForTitle";
import CoverPromptBox from "./CoverPromptBox";
import ConfigForCover from "./ConfigForCover";
import CreateOrder from "../CreateOrders";

const ConsolidatedInputForm = ({
  order,
  ordersFormData,
  setOrdersFormData,
  titlePrompt,
  setTitlePrompt,
  titleConfig,
  setTitleConfig,
  coverPrompt,
  setCoverPrompt,
  coverConfig,
  setCoverConfig,
}) => {
  return (
    <>
      <div className="ml-20 mr-20">
        <div className="grid grid-cols-5 grid-rows-2 gap-x-10 gap-y-0">
          <div className="col-span-2 row-span-2 ">
            <JsonInputComponent
              ordersData={order}
              ordersFormData={ordersFormData}
              setOrdersFormData={setOrdersFormData}
            />
          </div>
          <div className="col-span-2 row-span-1 ">
            <TitlePromptBox
              ordersData={order}
              titlePrompt={titlePrompt}
              setTitlePrompt={setTitlePrompt}
            />
          </div>
          <div className="col-span-1 row-span-1">
            <ConfigForTitle
              ordersData={order}
              titleConfig={titleConfig}
              setTitleConfig={setTitleConfig}
            />
          </div>
          <div className="col-span-2 row-span-1">
            <CoverPromptBox
              ordersData={order}
              coverPrompt={coverPrompt}
              setCoverPrompt={setCoverPrompt}
            />
          </div>
          <div className="col-span-1 row-span-1">
            <ConfigForCover
              ordersData={order}
              coverConfig={coverConfig}
              setCoverConfig={setCoverConfig}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 justify-center">
          <CreateOrder
            ordersFormData={ordersFormData}
            titleConfig={titleConfig}
            coverConfig={coverConfig}
            titlePrompt={titlePrompt}
            coverPrompt={coverPrompt}
          />
        </div>
      </div>
    </>
  );
};

export default ConsolidatedInputForm;
